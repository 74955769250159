@import "@/styles/_variables.scss";






















































































































































































































































































































































































































































































































































.name {
  cursor: pointer;
}

.new-folder-btn {
  padding: 0 20px;
}
#folder-edit-icon:hover {
  color: var(--text-color)!important;
}
