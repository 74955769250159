@import "@/styles/_variables.scss";





















































.draggable-item.presentDragTarget:not(.presentDragAgent) > a {
    outline: 2px dashed var(--app-primary-color);
}

tr.draggable-item.presentDragTarget {
    border-right: 2px dashed var(--app-primary-color);
    border-left: 2px dashed var(--app-primary-color);
}
