@import "@/styles/_variables.scss";



















































































































































































.uk-width-small {
  width: 215px;
}

.uk-height-small {
    height: 160px;
}

.folder {
  color: #cacaca;
}

.trash-icon {
  width: 20px;
  height: 20px;
}

.more-options {
  padding: 5px 0px;
}

.title-font {
  font-size: 14px;
}

.meta-data {
  font-size: 10px;
}
