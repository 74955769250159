@import "@/styles/_variables.scss";

































































































































































































































































.tooltip {
    color: #e69393;
    width: auto;
}
